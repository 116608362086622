import Navbar from "react-bootstrap/Navbar";
import React, { Component } from "react";
//import { connect } from 'react-redux'
//import { getToken } from '../../../store/actions/dashboardActions'
//import { Navigate } from 'react-router-dom'
// import {
//   Chart as ChartJS,
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
import "../assets/css/newDeal.css";
//import Dropdown from 'react-dropdown';
//import 'react-dropdown/style.css';
import { Container } from "react-bootstrap";

// ChartJS.register(
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

export default class NewDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      days: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
      userId: -1,
      url: "",
      post_urls: [],
      post_desc: "",
      qs: {},
      activeTab: "settings",
      post_flights: [],
    };
  }

  componentDidMount() {
    //this.props.getToken();
    //console.log(this.props.user.id);
  }

  generateFlight = (e) => {
    var url = this.state.url;
    //this.getAllUrlParts(url);
    var qs = this.getAllUrlParams(url);
    console.log(qs);

    // this.setState(post => ({
    //   post: { ...this.state.post, flights: [...this.state.post_flights, qs] }
    // }))

    this.setState({ post_flights: [...this.state.post_flights, qs] });
  };

  updateFlight = (e) => {
    var id = e.target.id;
    var value = e.target.value;
    if (id.includes("_")) {
      var flight_id = id.split("_")[1];
      var param = id.split("_")[0];

      console.log(id);

      // 1. Make a shallow copy of the items
      let items = [...this.state.post_flights];
      // 2. Make a shallow copy of the item you want to mutate
      let item = { ...items[flight_id] };
      // 3. Replace the property you're intested in
      item[param] = value;
      // 4. Put it back into our array. N.B. we *are* mutating the array here,
      //    but that's why we made a copy first
      items[flight_id] = item;
      // 5. Set the state to our new copy
      this.setState({ post_flights: items });

      // this.setState(post => ({
      //   ...post,
      //   flights: [
      //    flights[0]
      //     ...post.flights[0],
      //     to: "vvv"
      //   ]
      // }
      // ))

      console.log(this.state.post_flights);
    } else {
      this.setState({ [id]: value });
    }
  };

  handleChange = (e) => {
    if (e.target.id === "urlTxt") {
      var url = e.target.value;
      this.setState({ url: url });
    } else if (e.target.id.includes("urlTxt")) {
    } else if (e.target.id === "flightDesc" || e.target.id === "info") {
      var full_desc = this.urlify(e.target.value);
      var desc = full_desc.replace(/\r?\n/g, "<br />");
      this.setState({ post_desc: desc });
    }
  };

  urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  updateForm() {
    var flightCode = `
      <style>
      @media only screen and (min-width: 768px) {
        .flight_container {
          width: 50%;
        }
    
        .flight_row{
            display: flex;
        }
      }
    
      .post {
        direction: rtl;
        color: #000000;
      }
    
      .flight_container {
        display: flex;
        flex-direction: column;
        border: 2px solid #009dbc;
        border-radius: 15px;
        margin: 5px;
      }
    
      .flight_route {
        text-align: center;
        align-items: center;
        padding: 15px;
        display: flex;
        justify-content: space-between;
      }
    
      .flight_times {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
      }
    
      .flight_nights {
        padding: 15px;
        width: 50%;
        border-left: 1px solid #ededed;
      }
    
      .flight_dates {
        width: 50%;
        padding: 15px;
      }
    
      .flight_price {
        align-self: end;
        padding: 15px;
      }
    
      .flight_info {
        padding: 15px;
      }
    
      .flight_content {
        padding: 5px 15px;
      }
    
      .connection_name {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    
      .connection_name svg {
        transform: rotate(-90deg);
      }
    
      .flight_price label {
        font-size: 2em;
        color: #009dbc;
        font-weight: bold;
      }
    
      .short_name {
        font-size: 2em;
        min-width:70px;
      }
    
      .flight_btns {
        padding: 15px;
        display: flex;
        justify-content: space-around;
      }

      .flight_btns a{
        text-decoration: none !important;
      }
    
      .separator {
        display: flex;
        align-items: center;
      }
    
      .flight_btns button {
        font-family: Roboto, sans-serif;
        font-size: 1.1em;
        color: #fff;
        background-color: #009dbc;
        padding: 15px 20px;
        border: 1px solid #009dbc;
        box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
        border-radius: 10px;
        transition: 1000ms;
        transform: translateY(0);
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin:5px;
      }
    
      .btn {
        background-color: #ededed !important;
        color: #009dbc !important;
      }
    
      .subtitle {
        color: #009dbc;
        font-weight: bold;
        font-size: 1.2em;
      }
    </style>
    <div class="post">
      <div class="post_desc">${this.state.post_desc}</div>
      <div class="flights_container">`;

    this.state.post_flights.map((flight, id) => {
      if (id === 0 || id % 2 === 0) {
        flightCode += ` <div class="flight_row">`;
      }

      flightCode += ` <div class="flight_container">
        <div class="flight_header"></div>
        <div class="flight_route">
          <div class="from">
            <div class="short_name">${flight.from_short.toUpperCase()}</div>
            <div class="from_name">${flight.from}</div>
          </div>
          <div class="connections">
            <div class="connection_name">
              <div class="separator"> ------------------- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-airplane" viewBox="0 0 16 16">
                  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z" />
                </svg>
              </div>
              <div> ${
                flight.connections
                  ? "קונקשן ב" + flight.connections
                  : "טיסה ישירה"
              } </div>
            </div>
          </div>
          <div class="to">
          <div class="short_name">${flight.to_short.toUpperCase()}</div>
          <div class="from_name">${flight.to}</div>
          </div>
        </div>
      
        <div class="flight_route">
          <div class="from">
          <div class="short_name">${flight.to_short.toUpperCase()}</div>
          <div class="from_name">${flight.to}</div>
          </div>
          <div class="connections">
            <div class="connection_name">
              <div class="separator"> ------------------- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-airplane" viewBox="0 0 16 16">
                  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z" />
                </svg>
              </div>
              <div> ${
                flight.connections
                  ? "קונקשן ב" + flight.connections
                  : "טיסה ישירה"
              } </div>
            </div>
          </div>
          <div class="to">
          <div class="short_name">${flight.from_short.toUpperCase()}</div>
          <div class="from_name">${flight.from}</div>
          </div>
        </div>
        <div class="flight_price">החל מ <label> ${flight.startPrice} </label>
        </div>
        <div class="flight_times">
          <div class="flight_nights">
            <div class="subtitle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
          </svg> זמן </div>
            <div class="flight_content"> ${flight.nights} לילות </div>
          </div>
          <div class="flight_dates">
            <div class="subtitle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
          </svg> תאריכים </div>
            <div class="flight_content"> ${flight.depart} (${
        flight.departDay
      }') <br /> ${flight.return} (${flight.returnDay}') </div>
          </div>
        </div>
        <div class="flight_info">
          <div class="subtitle">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg> פרטים נוספים</div>
          <div class="flight_content">${flight.info} </div>
        </div>
        <div class="flight_btns">
         <a href="${
           flight.flight_url
         }" target="_blank" ><button>הזמנת טיסה</button></a>
         <a href="${
           flight.hotel_url
         }" target="_blank" >  <button class="btn">הזמנת מלון</button></a>
        </div>
      </div>`;

      if (id % 2 === 1) {
        flightCode += ` </div>`;
      }
    });

    flightCode += `
      </div>
    </div>
`;

    this.setState({ code: flightCode });
  }

  getAllUrlParts(url) {
    var pathArray = url ? url.split("/") : window.location.pathname.split("/");

    if (pathArray[4] !== "flights") return;

    var departD =
      "20" +
      pathArray[7][0] +
      pathArray[7][1] +
      "-" +
      pathArray[7][2] +
      pathArray[7][3] +
      "-" +
      pathArray[7][4] +
      pathArray[7][5];
    var returnDateD =
      "20" +
      pathArray[8][0] +
      pathArray[8][1] +
      "-" +
      pathArray[8][2] +
      pathArray[8][3] +
      "-" +
      pathArray[8][4] +
      pathArray[8][5];

    var depart =
      pathArray[7][4] +
      pathArray[7][5] +
      "/" +
      pathArray[7][2] +
      pathArray[7][3] +
      "/" +
      pathArray[7][0] +
      pathArray[7][1];
    var returnDate =
      pathArray[8][4] +
      pathArray[8][5] +
      "/" +
      pathArray[8][2] +
      pathArray[8][3] +
      "/" +
      pathArray[8][0] +
      pathArray[8][1];

    var departDay = this.state.days[new Date(departD + "T00:00:00Z").getDay()];
    var returnDay =
      this.state.days[new Date(returnDateD + "T00:00:00Z").getDay()];

    var flight = {
      from: pathArray[5],
      to: pathArray[6],
      depart: depart,
      return: returnDate,
      departDay: departDay,
      returnDay: returnDay,
    };

    return flight;

    // this.setState(post => ({
    //   post: { ...this.state.post, flights: [...this.state.post.flights, flight] }
    // }))

    // this.setState({
    //   from: pathArray[5],
    //   to: pathArray[6],
    //   depart: depart,
    //   return: returnDate,
    //   departDay: departDay,
    //   returnDay: returnDay,
    // })
  }
  getKayakObj(url) {
    var pathArray = url ? url.split("/") : window.location.pathname.split("/");

    if (pathArray[3] !== "flights") return;

    var departD = pathArray[5];
    var returnDateD = pathArray[6].split('?')[0];

    var depart =
      pathArray[5][8] +
      pathArray[5][9] +
      "/" +
      pathArray[5][5] +
      pathArray[5][6] +
      "/" +
      pathArray[5][2] +
      pathArray[5][3];
    var returnDate =
      pathArray[6][8] +
      pathArray[6][9] +
      "/" +
      pathArray[6][5] +
      pathArray[6][6] +
      "/" +
      pathArray[6][2] +
      pathArray[6][3];

    var departDay = this.state.days[new Date(departD + "T00:00:00Z").getDay()];
    var returnDay = this.state.days[new Date(returnDateD + "T00:00:00Z").getDay()];

    let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
    let TotalDays = Math.floor(
      (new Date(returnDateD + "T00:00:00Z").getTime() -
        new Date(departD + "T00:00:00Z").getTime()) /
        msDay
    );

    // we'll store the parameters here
    var obj = {};

    obj["startPrice"] = obj.qp_prevPrice + obj.qp_prevCurrency;
    obj["url"] = url;
    obj["from_short"] = pathArray[4].split('-')[0];
    obj["to_short"] = pathArray[4].split('-')[1];
    obj["depart"] = depart;
    obj["return"] = returnDate;
    obj["departDay"] = departDay;
    obj["returnDay"] = returnDay;
    obj["from"] = pathArray[4].split('-')[0];
    obj["to"] = pathArray[4].split('-')[1];
    obj["connections"] = "";
    obj["nights"] = TotalDays;
    obj["info"] = "";
    obj["flight_url"] = url;
    obj["hotel_url"] = "https://www.booking.com/index.html?aid=7930862";

    return obj;
  }

  getSkyObj(url) {
    var pathArray = url ? url.split("/") : window.location.pathname.split("/");

    if (pathArray[4] !== "flights") return;

    var departD =
      "20" +
      pathArray[7][0] +
      pathArray[7][1] +
      "-" +
      pathArray[7][2] +
      pathArray[7][3] +
      "-" +
      pathArray[7][4] +
      pathArray[7][5];
    var returnDateD =
      "20" +
      pathArray[8][0] +
      pathArray[8][1] +
      "-" +
      pathArray[8][2] +
      pathArray[8][3] +
      "-" +
      pathArray[8][4] +
      pathArray[8][5];

    var depart =
      pathArray[7][4] +
      pathArray[7][5] +
      "/" +
      pathArray[7][2] +
      pathArray[7][3] +
      "/" +
      pathArray[7][0] +
      pathArray[7][1];
    var returnDate =
      pathArray[8][4] +
      pathArray[8][5] +
      "/" +
      pathArray[8][2] +
      pathArray[8][3] +
      "/" +
      pathArray[8][0] +
      pathArray[8][1];

    var departDay = this.state.days[new Date(departD + "T00:00:00Z").getDay()];
    var returnDay =
      this.state.days[new Date(returnDateD + "T00:00:00Z").getDay()];

    let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
    let TotalDays = Math.floor(
      (new Date(returnDateD + "T00:00:00Z").getTime() -
        new Date(departD + "T00:00:00Z").getTime()) /
        msDay
    );

    // get query string from url (optional) or window
    var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split("#")[0];

      // split our query string into its component parts
      var arr = queryString.split("&");

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split("=");

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof a[1] === "undefined" ? true : a[1];

        // (optional) keep case consistent
        // paramName = paramName.toLowerCase();
        // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, "");
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === "string") {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    //prefer to use l.search if you have a location/link object
    var cleanUrl = "";

    cleanUrl = this.removeURLParameter(url, "associateid");
    cleanUrl = this.removeURLParameter(url, "utm_campaign");
    cleanUrl = this.removeURLParameter(url, "utm_medium");
    cleanUrl = this.removeURLParameter(url, "utm_source");
    cleanUrl = this.removeURLParameter(url, "irclickid");

    var myOtherUrl =
      "https://skyscanner.pxf.io/c/3491628/1027991/13416?associateid=AFF_TRA_19354_00001&u=" +
      encodeURIComponent(url).replace("%2A", "*");

    obj["startPrice"] = obj.qp_prevPrice + obj.qp_prevCurrency;
    obj["url"] = url;
    obj["from_short"] = pathArray[5];
    obj["to_short"] = pathArray[6];
    obj["depart"] = depart;
    obj["return"] = returnDate;
    obj["departDay"] = departDay;
    obj["returnDay"] = returnDay;
    obj["from"] = pathArray[5];
    obj["to"] = pathArray[6];
    obj["connections"] = "";
    obj["nights"] = TotalDays;
    obj["info"] = "";
    obj["flight_url"] = myOtherUrl;
    obj["hotel_url"] = "https://www.booking.com/index.html?aid=7930862";

    return obj;
  }

  getAllUrlParams(url) {
    if (url.includes("skyscanner")) {
      return this.getSkyObj(url);
    } else if (url.includes("kayak")) {
      return this.getKayakObj(url);
    }

    return;
  }

  removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split("?");
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + "=";
      var pars = urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i = pars.length; i-- > 0; ) {
        //idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    }
    return url;
  }

  handleTabClick(id) {
    if (id === this.state.activeTab) return;

    this.updateForm();
    this.setState({ activeTab: id });
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setState({ user: this.props.user });
    }

    if (this.props.token !== prevProps.token) {
    }

    if (this.props.profile !== prevProps.profile) {
      if (typeof this.props.profile.customerId !== "undefined") {
      }
    }
  }

  render() {
    const { auth } = this.props;
    return (
      <>
        <Container className="post-container">
          {/* NAVBAR */}
          <Navbar
            expand="xs"
            variant="dark"
            bg="dark"
            fixed="bottom"
            className="navbar"
          >
            <Navbar.Brand
              onClick={() => this.handleTabClick("settings")}
              className="settings"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-gear"
                viewBox="0 0 16 16"
              >
                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
              </svg>
              <div className="nav-title">הגדרות</div>
            </Navbar.Brand>
            <Navbar.Brand
              onClick={() => this.handleTabClick("preview")}
              className="preview"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
              </svg>
              <div className="nav-title">תצוגה</div>
            </Navbar.Brand>
            <Navbar.Brand
              onClick={() => this.handleTabClick("code")}
              className="code"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-code-slash"
                viewBox="0 0 16 16"
              >
                <path d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z" />
              </svg>
              <div className="nav-title">קוד</div>
            </Navbar.Brand>
          </Navbar>

          {/* INIT SETTINGS */}
          <div className="deal-content">
            <div
              className={`settingsTab ${
                this.state.activeTab === "settings" ? "isActive" : ""
              }`}
            >
              <div className="urlConf">
                <div className="d-flex flex-row">
                  <div>
                    <label>הזן תיאור</label>
                    <textarea
                      id="flightDesc"
                      rows="10"
                      name="flightDesc"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div></div>
                  <div className="urls">
                    <label>הזן כתובת</label>
                    <input
                      type="text"
                      className="urlTxt"
                      id="urlTxt"
                      name="urlTxt"
                      onChange={this.handleChange}
                    />
                    <button
                      className="addflight btn btn-sm"
                      onClick={this.generateFlight}
                    >
                      +
                    </button>
                    <br />

                    {/* {this.state.post_flights ? this.state.post_flights.map((flight, id) => {
                      return <div key={id}><label> {flight.from} - {flight.to} ({flight.depart}-{flight.return})</label></div>
                    }) : ""} */}
                  </div>
                </div>
              </div>

              {/* SETTINGS */}
              <div className="dealConf">
                {this.state.post_flights
                  ? this.state.post_flights.map((flight, id) => {
                      return (
                        <div key={id} className="editflight">
                          <label>
                            {" "}
                            {flight.from_short} - {flight.to_short} (
                            {flight.depart}-{flight.return})
                          </label>
                          <div className="field">
                            <label>מוצא</label>
                            <input
                              size="9"
                              type="text"
                              id={"from_" + id}
                              onChange={this.updateFlight}
                              value={flight.from.toUpperCase()}
                            />
                            <label>יעד</label>
                            <input
                              size="9"
                              type="text"
                              id={"to_" + id}
                              onChange={this.updateFlight}
                              value={flight.to.toUpperCase()}
                            />
                          </div>
                          <div className="field">
                            <label>קונקשן</label>
                            <input
                              size="9"
                              type="text"
                              onChange={this.updateFlight}
                              id={"connections_" + id}
                              value={flight.connections}
                            />
                            <label>מחיר</label>
                            <input
                              size="9"
                              type="text"
                              onChange={this.updateFlight}
                              id={"startPrice_" + id}
                              value={flight.startPrice}
                            />
                          </div>
                          <div className="field">
                            <label>פרטים נוספים</label>
                          </div>

                          <div className="field">
                            <textarea
                              rows="5"
                              style={{ width: "100%" }}
                              onChange={this.updateFlight}
                              id={"info_" + id}
                              value={flight.info}
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            {/* <div className={`flightsTab ${this.state.activeTab === 'flights' ? "isActive" : ""}`}>


              <div className="dealConf">
                {this.state.post_flights ? this.state.post_flights.map((flight, id) => {
                  return <div key={id}>
                    {(flight.from) ? <div className="field"><label>מוצא</label><input size="9" type='text' id={'from_' + id} onChange={this.updateFlight} value={flight.from.toUpperCase()} /><label>יעד</label><input size="9" type='text' id={'to_' + id} onChange={this.updateFlight} value={flight.to.toUpperCase()} /></div> : ""}
                    {(flight.to) ? <div className="field"></div> : ""}
                    {(flight.depart) ? <div className="field"><label>תאריכים</label><input size="9" type='text' onChange={this.updateFlight} id={'depart_' + id} value={flight.depart} /><label>-</label><input size="9" type='text' onChange={this.updateFlight} id={'return_' + id} value={flight.return} /></div> : ""}
                    {(flight.return) ? <div className="field"></div> : ""}
                    {(flight.adultsv2) ? <div className="field"><label>נוסעים</label><input size="9" type='text' onChange={this.updateFlight} id={'adults_' + id} value={flight.adultsv2} /><label>ילדים</label><input size="9" type='text' onChange={this.updateFlight} id={'children_' + id} value={flight.childrenv2} /></div> : ""}
                    {(flight.cabinclass) ? <div className="field"><label>מחלקה</label><input size="9" type='text' onChange={this.updateFlight} id={'class_' + id} value={flight.cabinclass} /><label>מחיר</label><input size="9" type='text' onChange={this.updateFlight} id={'price_' + id} /></div> : ""}


                  </div>
                }) : ""}

              </div>


            </div> */}

            <div
              className={`previewTab ${
                this.state.activeTab === "preview" ? "isActive" : ""
              }`}
              style={{ maxWidth: 500 }}
            >
              {this.state.post_flights.length > 0 ? (
                <div dangerouslySetInnerHTML={{ __html: this.state.code }} />
              ) : (
                <label>לא הוזן קישור</label>
              )}
              {/* 
          <iframe srcdoc={`<html><body>${this.state.code}</html></body>`}></iframe>;
            {(this.state.from) ? <div>
              <label>
                {this.state.from.toUpperCase()}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-airplane-fill" viewBox="0 0 16 16" style={{
                  transform: 'rotate(90deg)',
                  marginRight: 5,
                  marginLeft: 5
                }}>
                  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Z" />
                </svg>
                {this.state.to.toUpperCase()}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-airplane-fill" viewBox="0 0 16 16" style={{
                  transform: 'rotate(90deg)',
                  marginRight: 5,
                  marginLeft: 5
                }}>
                  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Z" />
                </svg>
                {this.state.from.toUpperCase()}
              </label>
            </div> : <label>לא הוזן קישור</label>}

            <label>{this.state.departDay}</label> */}
            </div>

            <div
              className={`codeTab ${
                this.state.activeTab === "code" ? "isActive" : ""
              }`}
            >
              {this.state.post_flights.length > 0 ? (
                <textarea
                  className="outputCode"
                  id="outputCode"
                  rows="13"
                  value={this.state.code}
                  readOnly={true}
                ></textarea>
              ) : (
                <label>לא הוזן קישור</label>
              )}
            </div>
          </div>

          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css"
            integrity="sha512-YWzhKL2whUzgiheMoBFwW8CKV4qpHQAEuvilg9FAn5VJUDwKZZxkJNuGM4XkWuk94WCrrwslk8yWNGmY1EduTA=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
        </Container>
      </>
    );
  }
}
// }

// const mapStateToProps = (state) => {
//   return {
//     token: state.dashboard.token,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getToken: () => dispatch(getToken()),
//     dispatch
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
